var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"profile"},[_c('h2',{staticClass:"profile__title tw-text-xl tw-border-0 tw-border-solid tw-mb-12 tw-border-b tw-border-gray-900"},[_vm._v(" "+_vm._s(_vm.$t('account.profile.title'))+" ")]),_c('form',{staticClass:"profile__form tw-w-full",attrs:{"disabled":_vm.$wait.is('updating first and last name')},on:{"submit":function($event){$event.preventDefault();return _vm.submitted.apply(null, arguments)}}},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var dirty = ref.dirty;
return [_c('ValidationProvider',{ref:"first_name-provider",attrs:{"name":_vm.$t('account.profile.fields.first_name'),"rules":"required|max:255","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var errors = ref.errors;
return [_c('ctk-input-text',{staticClass:"tw-mb-2",attrs:{"id":"first_name","label":_vm._f("capitalize")(_vm.$t('account.profile.fields.first_name')),"disabled":_vm.$wait.is('updating first and last name'),"error":invalid && validated,"hint":errors[0],"name":"first_name","autocomplete":"given-name","required":""},model:{value:(_vm.formData.first_name),callback:function ($$v) {_vm.$set(_vm.formData, "first_name", $$v)},expression:"formData.first_name"}})]}}],null,true)}),_c('ValidationProvider',{ref:"last_name-provider",attrs:{"name":_vm.$t('account.profile.fields.last_name'),"rules":"required|max:255","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
var errors = ref.errors;
return [_c('ctk-input-text',{staticClass:"tw-mb-2",attrs:{"id":"last_name","label":_vm._f("capitalize")(_vm.$t('account.profile.fields.last_name')),"disabled":_vm.$wait.is('updating first and last name'),"error":invalid && validated,"hint":errors[0],"data-vv-as":_vm.$t('account.profile.fields.last_name'),"name":"last_name","autocomplete":"family-name","required":""},model:{value:(_vm.formData.last_name),callback:function ($$v) {_vm.$set(_vm.formData, "last_name", $$v)},expression:"formData.last_name"}})]}}],null,true)}),_c('ctk-input-text',{staticClass:"tw-mb-2",attrs:{"id":"email","value":_vm.getUserInfos ? _vm.getUserInfos.email : null,"type":"email","label":_vm._f("capitalize")(_vm.$t('account.profile.fields.email')),"name":"email","autocomplete":"email","disabled":"","readonly":"","required":""}}),_c('p',{staticClass:"profile__form__email-explanation tw-italic"},[_vm._v(" "+_vm._s(_vm.$t('account.profile.paragraphs.email'))+" ")]),_c('div',{staticClass:"profile__form__buttons tw-flex tw-justify-between tw-mt-10"},[_c('ui-button',{attrs:{"disabled":!dirty || _vm.$wait.is('updating first and last name'),"variant":"link","type":"button"},on:{"click":_vm.reset}},[_vm._v(" "+_vm._s(_vm.$t('account.buttons.cancel'))+" ")]),_c('ui-button',{staticClass:"profile__form__buttons__save tw-mr-2",attrs:{"loading":_vm.$wait.is('updating first and last name'),"disabled":_vm.$wait.is('updating first and last name'),"variant":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('account.buttons.save'))+" ")])],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }