<template>
  <div class="profile">
    <h2 class="profile__title tw-text-xl tw-border-0 tw-border-solid tw-mb-12 tw-border-b tw-border-gray-900">
      {{ $t('account.profile.title') }}
    </h2>

    <form
      class="profile__form tw-w-full"
      :disabled="$wait.is('updating first and last name')"
      @submit.prevent="submitted"
    >
      <ValidationObserver
        ref="observer"
      >
        <template slot-scope="{ dirty }">
          <ValidationProvider
            ref="first_name-provider"
            :name="$t('account.profile.fields.first_name')"
            rules="required|max:255"
            slim
          >
            <template slot-scope="{ invalid, validated, errors }">
              <ctk-input-text
                id="first_name"
                v-model="formData.first_name"
                :label="$t('account.profile.fields.first_name') | capitalize"
                :disabled="$wait.is('updating first and last name')"
                :error="invalid && validated"
                :hint="errors[0]"
                name="first_name"
                autocomplete="given-name"
                class="tw-mb-2"
                required
              />
            </template>
          </ValidationProvider>
          <ValidationProvider
            ref="last_name-provider"
            :name="$t('account.profile.fields.last_name')"
            rules="required|max:255"
            slim
          >
            <template slot-scope="{ invalid, validated, errors }">
              <ctk-input-text
                id="last_name"
                v-model="formData.last_name"
                :label="$t('account.profile.fields.last_name') | capitalize"
                :disabled="$wait.is('updating first and last name')"
                :error="invalid && validated"
                :hint="errors[0]"
                :data-vv-as="$t('account.profile.fields.last_name')"
                name="last_name"
                autocomplete="family-name"
                class="tw-mb-2"
                required
              />
            </template>
          </ValidationProvider>
          <ctk-input-text
            id="email"
            :value="getUserInfos ? getUserInfos.email : null"
            type="email"
            :label="$t('account.profile.fields.email') | capitalize"
            name="email"
            autocomplete="email"
            disabled
            readonly
            required
            class="tw-mb-2"
          />

          <p
            class="profile__form__email-explanation tw-italic"
            data-test="email-explanation"
          >
            {{ $t('account.profile.paragraphs.email') }}
          </p>

          <div class="profile__form__buttons tw-flex tw-justify-between tw-mt-10">
            <ui-button
              :disabled="!dirty || $wait.is('updating first and last name')"
              variant="link"
              type="button"
              data-test="cancel-button"
              @click="reset"
            >
              {{ $t('account.buttons.cancel') }}
            </ui-button>
            <ui-button
              :loading="$wait.is('updating first and last name')"
              :disabled="$wait.is('updating first and last name')"
              class="profile__form__buttons__save tw-mr-2"
              variant="primary"
              type="submit"
              data-test="save-button"
            >
              {{ $t('account.buttons.save') }}
            </ui-button>
          </div>
        </template>
      </ValidationObserver>
    </form>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  import { showToaster } from '@/services/Toaster'
  import store from '@/store'
  import { Me } from '@/resources'
  import handlePropertyPathViolations from '@/resources/handlers/violations'

  import CtkInputText from '@/components/CtkInputs/CtkInputText'

  /**
   * @module view - profile
   */
  export default {
    name: 'Profile',
    components: {
      CtkInputText
    },
    metaInfo () {
      return {
        title: this.$t('account.profile.title')
      }
    },
    beforeRouteEnter (to, from, next) {
      store.dispatch('setAppReady', true)
      next()
    },
    data () {
      return {
        formData: {
          first_name: null,
          last_name: null,
          email: null
        }
      }
    },
    mounted () {
      this.reset()
    },
    computed: {
      ...mapGetters('auth', [
        'getUserInfos'
      ])
    },
    methods: {
      ...mapActions('auth', [
        'setUserInfos'
      ]),
      submitted () {
        const fields = {
          first_name: this.formData.first_name.trim(),
          last_name: this.formData.last_name.trim()
        }

        if (this.$wait.is('updating first and last name')) {
          return
        }

        this.$refs.observer.validate()
          .then(valid => {
            if (!valid) {
              return
            }

            this.$wait.start('updating first and last name')
            Me.identity({}, fields)
              .then(() => {
                /**
                 * Update the informations locally
                 */
                this.setUserInfos({
                  ...this.getUserInfos,
                  ...fields
                })

                showToaster(
                  this,
                  this.$t('account.profile.paragraphs.name_updated'), {
                    type: 'success',
                    position: 'bottom-right'
                  }
                )
              })
              .catch(err => {
                if (!err.response) return

                const { data } = err.response
                if (data && data.error && data.error.violations) {
                  handlePropertyPathViolations.call(this, data.error.violations)
                } else {
                  showToaster(
                    this,
                    data.error.title, {
                      type: 'error',
                      position: 'bottom-right'
                    }
                  )
                }
              })
              .finally(() => this.$wait.end('updating first and last name'))
          })
      },
      /**
       * Set the fields information according to the current user informations
       * @function reset
       */
      reset () {
        const {
          first_name: firstName,
          last_name: lastName
        } = this.getUserInfos

        this.formData = {
          first_name: firstName,
          last_name: lastName
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

  .profile {
    &__title {
      @media only screen and (max-width: 857px) {
        display: none;
      }
    }
  }

</style>
